import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Observable, Subject } from 'rxjs';


import { catchError, map } from 'rxjs/operators';
import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { RegisterApplicantInformation } from './register-applicant-information';
import { RegisterApplicantInformationComponent } from './register-applicant-information.component';

import { UserData, User } from '../shared/user/user';
import { PrefilledUser } from '../shared/user/prefilled-user';

import { AuthenticationService } from '../shared/auth/authentication.service';

import { Lookup } from '../shared/lookup/lookup';
import { LookupModel } from '../shared/lookup-model'

@Injectable()
export class RegistrationService extends BaseService {

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService
    ) { super(); }

    public getSexList(): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_UserLookup_Sex)
            .subscribe({
                next: (data: any) => {
                    const response: Lookup[] = data["sex"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getCountryList(): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_UserLookup_Country)
            .subscribe({
                next: (data: any) => {
                    const response: Lookup[] = data["countries"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getRegionList(countryCode: string): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_UserLookup_Region(countryCode))
            .subscribe({
                next: (data: any) => {
                    const response: Lookup[] = data["regions"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getAgencyList(countryCode: string): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_UserLookup_Agency(countryCode))
            .subscribe({
                next: (data: any) => {
                    const response: Lookup[] = data["agencies"];
                    if (countryCode === 'MX' && response.length > 0) {
                        // console.log(`Applying from Mexico: ${JSON.stringify(response)}`);
                        // CA-2046
                        response.push(new Lookup(response[0].code, "None")); // Default to CA agency Id
                    }
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getPrefilledUser(id: string): Observable<PrefilledUser> {
        let subject = new Subject<PrefilledUser>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_User_GetPrefilled(id))
            .subscribe(
                data => {
                    const response: PrefilledUser = this.getResultData(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                });

        return subject;
    }

    public getTelephoneIddList(): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_UserLookup_TelephoneIdd)
            .subscribe({
                next: (data: any) => {
                    const response: Lookup[] = data["idd"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public checkEmailAlreadyRegistered(email: string): Observable<boolean> {
        let subject = new Subject<boolean>();
        this.http
            .post<BaseResponse>(environment.ServiceUrl_User_CheckEmailAlreadyRegistered, { email })
            .subscribe({
                next: (data: any) => {
                    // console.info(`Check email directive: ${JSON.stringify(data)}`);
                    const response: boolean = data.result["exists"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    /**
     * Register the passed user details
     * @param email (string) 
     * @param password (string)
     * @param firstName (string)
     * @param lastName (string)
     * @param sex (string)
     * @param countryCode (string)
     * @param regionId (number)
     * @param agencyId (number)
     * @param idd (number)
     * @param telephone (string)
     * @param birthDate (Date)
     * @returns Observable<UserData>
     */
    //public Register(email: string, password: string, firstName: string, lastName: string, sex: string, countryCode: string, regionId: number, telephone: string, birthDate: Date): Observable<UserData> {
    public Register(email: string, password: string, firstName: string, lastName: string, sex: string, countryCode: string, regionId: number, agencyId: number, idd: number, telephone: string, birthDate: Date, agreeTerms: boolean, optInAll: boolean): Observable<UserData> {
        let subject = new Subject<UserData>();

        // console.log(`Register: ${email}, ${password}, ${firstName}, ${lastName}, ${sex}, ${countryCode}, ${regionId}, ${idd}, ${telephone}, ${birthDate}`);

        this.http
            .post<BaseResponse>(environment.ServiceUrl_User_Register,
                {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    sex: sex,
                    country: countryCode,
                    region: regionId,
                    agencyId: agencyId,
                    iddCodeId: idd,
                    telephone: telephone,
                    birthDate: birthDate,
                    agreeTerms: agreeTerms,
                    optInAll: optInAll
                })
            .subscribe(
                data => {
                    const response: UserData = this.getResultData<UserData>(data);
                    this.authService.storeUserData(response);

                    subject.next(response);
                },
                error => {
                    subject.error(error);
                });

        return subject;
    }
}