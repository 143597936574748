import { getEnvironment } from './ca-environment'

export const environment = getEnvironment({
    isProduction: false,
    isAzure: false,
    applicationServiceUrl: 'https://p1-api.mycampamerica.com',
    skillServiceUrl: 'https://p1-api.mycampamerica.com',
    insuranceServiceUrl: 'https://p1-api.mycampamerica.com',
    interviewServiceUrl: 'https://p1-api.mycampamerica.com',
    interviewerServiceUrl: 'https://p1-api.mycampamerica.com',
    issueServiceUrl: 'https://p1-api.mycampamerica.com',
    flightServiceUrl: 'https://p1-api.mycampamerica.com',
    formServiceUrl: 'https://p1-api.mycampamerica.com',
    messageServiceUrl: 'https://p1-api.mycampamerica.com',
    paymentServiceUrl: 'https://p1-api.mycampamerica.com',
    referenceServiceUrl: 'https://p1-api.mycampamerica.com',
    trackerServiceUrl: 'https://p1-api.mycampamerica.com',
    uploadServiceUrl: 'https://p1-api.mycampamerica.com',
    userServiceUrl: 'https://p1-api.mycampamerica.com',
    signalRServiceUrl: 'https://p1-api.mycampamerica.com',
    placementServiceUrl: 'https://p1-api.mycampamerica.com',
    eventServiceUrl: 'https://p1-api.mycampamerica.com',
    featureServiceUrl: 'https://p1-api.mycampamerica.com',
    selfProposalServiceUrl: 'https://p1-api.mycampamerica.com',
    dbsServiceUrl: 'https://p1-api.mycampamerica.com',
    orientationServiceUrl: 'https://p1-api.mycampamerica.com',
    //applicationServiceUrl: 'http://localhost:30004/api',
    //formServiceUrl: 'http://localhost:30022/api',
    //flightServiceUrl: 'http://localhost:30007/api',
    //interviewServiceUrl: 'http://localhost:30005/api', //
    //interviewerServiceUrl: 'http://localhost:30005/api', //
    //paymentServiceUrl: 'http://localhost:30010/api',
    //placementServiceUrl: 'http://localhost:30011/api',
    //referenceServiceUrl: 'http://localhost:30013/api',
    //trackerServiceUrl: 'http://localhost:30012/api',
    //uploadServiceUrl: 'http://localhost:30014/api',
    //userServiceUrl: 'http://localhost:30000/api',
    //dbsServiceUrl: 'http://localhost:30020/api', //

    cloudImage: {
        id: 'exfolse'
    },

    // dotPay: {
    //   account: '792770',
    //   paymentsUrl: 'https://ssl.dotpay.pl/test_payment/',
    //   language: 'EN',
    // },
    secureTrading: {
        paymentsUrl: 'https://payments.securetrading.net/process/payments/choice',
        siteReference: 'test_aifsca61207',
        profile: "default",
        version: "2"
    },

    sessionTimeout: {
        idle: 600,
        timeout: 1,
    },

    googleMaps: {
        apiKey: "AIzaSyB6Q9YpMMshASCdzytnboAMqnLGoNL86vg"
    },
    signalRUrl: 'https://p1-api.mycampamerica.com/push',
    appInsights: {
        instrumentationKey: '6d549cfa-8349-4cd7-8d30-51b000563b4e'
    },
    
    googleAnalytics: {
        measurementId: 'G-5C0NE8E6CK'
    },
    applicantView: {
        isPayNowEnabled: false,
        isDs2019LinkViewable: false,
        isMedicalInformationViewable: false,
        isHeaderVisible: true,
        isActivityCheckActive: true
    }
})
