import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { IBusinessRuleData } from '../rule/business-rule';

import { environment } from '../../../environments/environment';

import { CreateApplication } from './create-application';
import { Season } from './season';

import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';
import { InvokeFunctionExpr } from '@angular/compiler';

@Injectable()
export class ApplicationService extends BaseService implements IBusinessRuleData {

    constructor(
        private http: HttpClient) { super(); }

    /**
     * Check to see whether we can create an applicant in the current season,
     * based on the current applicant country.
     * @param applicantId (number) The applicant id
     * @returns 
     */
    public canCreateApplicationForApplicant(applicantId: number): Observable<CreateApplication> {
        let subject = new Subject<CreateApplication>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_CanCreateApplication_Request(applicantId)}`)
            .subscribe(
                data => {
                    const response = this.getResultData<CreateApplication>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                });

        return subject;
    }

    public createApplicationSeasonIsClosed(c: CreateApplication): boolean {
        // // console.info(`(c.flags & 4) = ${(c.flags & 4)}`);
        return (c.flags & 4) == 4;
    }

    public createApplicationTooYoung(c: CreateApplication): boolean {
        // // console.info(`(c.flags & 2) = ${(c.flags & 2)}`);
        return (c.flags & 2) == 2;
    }

    public createApplicationNotApplicationCountry(c: CreateApplication): boolean {
        // // console.info(`(c.flags & 8) = ${(c.flags & 8)}`);
        return (c.flags & 8) == 8;
    }

    public createApplicationCountryClosed(c: CreateApplication): boolean {
        // // console.info(`(c.flags & 16) = ${(c.flags & 16)}`);
        return (c.flags & 16) == 16;
    }

    public selectAgency(applicantId: number, agencyId: number): Observable<boolean> {
        const s = new Subject<boolean>();

        this.http.post<BaseResponse>(
            environment.ServiceUrl_SelectAgency(applicantId),
            { applicantId: applicantId, agencyId: agencyId }
        ).subscribe({
            next: (response: BaseResponse) => {
                const data = this.getResultData<SelectAgencyResponse>(response);
                s.next(data.success);
            },
            error: (error: any) => {
                s.error(error);
            }
        });

        return s;
    }

    public loadRuleData(data: any) {
        if (data) {
            // Ensure that no pre-existing fields are sticking around
            this.applicationData.clear();
            // console.info('setting rule data');
            for (let k in data) {
                let value = data[k];
                this.setValue(k, value);
            }
        }
    }

    public writeDataForForm(applicantId: number, applicationId: number, dataSource: string): Observable<any> {
        let subject = new Subject<any>()

        this.writeToUrl(applicantId, applicationId, dataSource)
            .subscribe(
                response => {
                    if (response) {
                        const data = response._body;
                        // console.debug(`Response was ${JSON.stringify(data, null, 2)}`);
                        subject.next(response);
                    }
                },
                error => console.error(error)
            );

        return subject
    }

    public loadDataForForm(applicantId: number, applicationId: number, dataSource: string) {
        // console.debug(`Loading data for applicant ${applicantId}, application ${applicationId}, source ${dataSource}`)
        this.loadFromUrl(applicantId, applicationId, dataSource)
            .subscribe(result => {
                for (let k in result) {
                    let value = result[k];
                    // console.info(`K,V = ${k}, ${value}`);
                    this.setValue(k, value);
                }

                // console.debug(`ApplicationData = ${this.applicationData}`);
            });
    }

    public saveFormDataForLater(pkId: number, pkType: string, data: any): Observable<SaveForLaterResponse> {
        let s = new Subject<SaveForLaterResponse>();

        // console.log(`Saving data for ${pkType} ${pkId}`);

        this.http.post<BaseResponse>(
            `${environment.ServiceUrl_WriteTemporaryForm(pkId, pkType)}`, { data: data })
            .subscribe(
                response => {
                    const data = this.getResultData<SaveForLaterResponse>(response);
                    s.next(data);
                },
                error => {
                    s.error(error);
                }
            );

        return s;
    }

    public applicantDetailsForApplication(applicationId: number): Observable<ApplicantApplicationDetails> {
        let s = new Subject<ApplicantApplicationDetails>();

        this.http.get<BaseResponse>(environment.ServiceUrl_ApplicantDetailsForApplication(applicationId))
            .subscribe(
                response => {
                    const data = this.getResultData<ApplicantApplicationDetails>(response);
                    s.next(data);
                },
                error => s.error(error)
            );

        return s;
    }

    public checkForNewSeason(applicationId: number): Observable<Season> {
        let s = new Subject<Season>();

        this.http.get<BaseResponse>(environment.ServiceUrl_CheckForNewSeason(applicationId))
            .subscribe(
                response => {
                    const data = this.getResultData<Season>(response);
                    s.next(data);
                },
                error => s.error(error)
            );

        return s;
    }

    public updateInterviewReady(applicationId: number, interviewReady: boolean): Observable<any> {
        let s = new Subject<any>();

        this.http.post<BaseResponse>(environment.ServiceUrl_UpdateInterviewReady, { applicationId: applicationId, interviewReady: interviewReady })
            .subscribe(
                response => {
                    const data = this.getResultData<any>(response);
                    s.next(data);
                },
                error => {
                    s.error(error);
                }
            );

        return s;
    }

    public resetFormCallCount() {
        if (this.callCount > 0) { console.debug(`rcc: ${this.callCount}`); }
        this.callCount = 0;
    }

    // -------------------------------------------------------------
    // Implementation of IBusinessRuleData interface
    // -------------------------------------------------------------

    public initialise(): void {
        // TODO(ian): have initialise specify the form or sequence it's using?
    }

    /**
     * Read in data for the current application.
     * If we are not passed an applicationId (===0), we use the call
     * to applicant/id rather than application/id.
     * @param applicantId (number) - current user's applicantId
     * @param applicationId (number) - current user's applicationId
     * @param dataSource (string) - the url to read data in from
     */
    loadFromUrl(applicantId: number, applicationId: number, dataSource: string): Observable<any> {
        let urlTarget = applicationId !== 0
            ? `${environment.ServiceUrl_LoadApplicationFormDataSet(applicationId, dataSource)}`
            : `${environment.ServiceUrl_LoadApplicantFormDataSet(applicantId, dataSource)}`;

        // console.log(`Loading applicationData from: '${urlTarget}'`);

        let subject = new Subject<any>();

        this.http
            .get<BaseResponse>(urlTarget)
            .subscribe({
                next: (data: any) => {
                    if (data.result) {
                        const response = data.result["data"];
                        subject.next(response);
                    } else {
                        console.error(`WHAM: Didn't get expected data from the service: check ${urlTarget}, response was:`, data);
                        subject.error(new Error(`Calling ${urlTarget} failed!`));
                    }
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    callCount = 0;

    public writeToUrl(applicantId: number, applicationId: number, dataSource: string): Observable<any> {
        let urlTarget = applicationId !== 0
            ? `${environment.ServiceUrl_LoadApplicationFormDataSet(applicationId, dataSource)}`
            : `${environment.ServiceUrl_LoadApplicantFormDataSet(applicantId, dataSource)}`;

        // console.log(`Saving applicationData to: '${urlTarget}'`);
        let subject = new Subject<any>();

        if (this.callCount > 0) {
            // console.warn(`Call in process (count=${this.callCount})`);
            return subject;
        }

        this.callCount++;

        this.http
            .post<BaseResponse>(urlTarget, { data: this.getCurrentData() })
            .subscribe(
                data => {
                    const response = data.result;
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                    this.callCount--;
                });

        return subject;
    }

    public seasonOpenStatus(applicationId: number): Observable<SeasonStatusResponse> {
        return this.http
            .get<SeasonStatusResponse>(`${environment.ServiceUrl_ApplicationSeasonStatus(applicationId)}`);
    }

    public getValue(name: string): any {
        //console.info(`Someone's asking for '${name}'`);

        if (this.applicationData.has(name)) {
            // console.info(`${name} exists with value ${this.applicationData.get(name)}`);
            return this.applicationData.get(name);
        }
    }

    // See https://stackoverflow.com/a/56833507
    // Hacky, but I just need to get compilation working (data - was : object)
    public setValueFromObject(data: {[index: string]: any}) {
        for (var p in data) {
            if (data.hasOwnProperty(p)) {
                // console.info(`Property: ${p}`, rd[p]);
                this.setValue(p, data[p]);
            }
        }
    }

    public setValue(name: string, value: any) {
        // console.info(`RULES: Someone's setting ${name} to '${value}'`);

        this.applicationData.set(name, value);

        // console.debug(`DATA: ${JSON.stringify(this.getCurrentData(), null, 2)}`);
    }

    public getCurrentData(): any {
        let data: any = {};
        this.applicationData.forEach((value: string, key: string) => {
            if (Object.prototype.toString.call(value) === '[object Date]')
                console.log(`Key ${key} is a date with value ${value}`);

            data[key] = value;
        });

        return data;
    }

    applicationData: Map<string, any> = new Map<string, any>();

    // -------------------------------------------------------------
    // Implementation of IBusinessRuleData interface
    // -------------------------------------------------------------
}

export class SaveForLaterResponse implements ResponseData {
    success!: boolean;
}

export class SelectAgencyResponse implements ResponseData {
    success!: boolean;
}

export class ApplicantApplicationDetails implements ResponseData {
    applicantId!: number;
    applicationId!: number;
    country!: string;
    userState!: string;
    season!: number;
}

export class SeasonStatusDetails {
    season!: number;
    countryTemporaryClosed!: boolean;
    lateSeasonClosure!: boolean;
}

export class SeasonStatusResponse implements ResponseData {
    result!: SeasonStatusDetails;
}
