import { NgModule } from '@angular/core'

import { CloudimagePipe, CloudimageCropPipe, CloudimageHeightPipe, CloudimageWidthPipe } from './cloudimage.pipe'
import { TruncatePipe } from './trucate.pipe'
import { MapToIterable } from './map-to-iterable.pipe'
import { DateSuffix } from './date-suffix.pipe'

@NgModule({
    declarations: [
        CloudimagePipe, CloudimageCropPipe, CloudimageHeightPipe, CloudimageWidthPipe,
        TruncatePipe,
        DateSuffix,
        MapToIterable
    ],
    exports: [
        CloudimagePipe, CloudimageCropPipe, CloudimageHeightPipe, CloudimageWidthPipe,
        TruncatePipe,
        DateSuffix,
        MapToIterable
    ]
})
export class PipesModule { }
