<div class="upload-component" *ngIf="limits?.canUploadMore">
    <!-- Placeholder -->
    <h4>{{SubHeader}}</h4>
    <p>{{uploadTextTop}}</p>
    <img *ngIf='currentState == UploadComponentState.Default' (click)='file.click()' [src]=uploadImage
        [alt]='imageAltText' class='image-select {{imageTypeClass}} center-block'>

    <div *ngIf="!isPDF">
        <div
            *ngIf="(currentState == UploadComponentState.FileSelectedForUpload || currentState == UploadComponentState.FileUploading ) && documentType != DocumentType.Video">
            <img *ngFor="let selectedFile of selectedFiles" [src]='sanitize(selectedFileURL(selectedFile))'
                class='image-select {{imageTypeClass}} center-block' (click)="cancelUpload(selectedFile)">

            <img *ngIf="canSelectAnyMoreFiles" (click)='file.click()' src='assets/img/no-pic.png'
                class='image-select {{imageTypeClass}} center-block' alt='add another page'>
        </div>
    </div>
    <div *ngIf="isPDF" class="pdf">
        <p>{{pdfFileName}}</p>
        <img src="/assets/img/pdf.svg">
    </div> 
    <div
        *ngIf="(currentState == UploadComponentState.FileSelectedForUpload || currentState == UploadComponentState.FileUploading ) && documentType == DocumentType.Video">
        <vg-player (onPlayerReady)="onPlayerReady($event)">
            <video [vgMedia]="$any(media)" #media preload="auto" controls crossorigin>
                <source *ngFor="let selectedFile of selectedFiles" [src]="sanitize(selectedFileURL(selectedFile))"
                    type="video/mp4">
            </video>
        </vg-player>
    </div>
</div>
<!-- File is uploaded -->
<div *ngIf="currentState == UploadComponentState.FileUploaded">
    <div class="upload-component"
        *ngIf="documentType != DocumentType.Portrait">
        <img *ngFor="let page of uploadedFile?.pages" src='uploadedImageUri(page)'
            class='image-select {{imageTypeClass}} center-block' (error)="handleMissingImage($event)">
    </div>
    <div class="upload-component"
        *ngIf="documentType == DocumentType.Portrait">
        <user-image [imageClass]="imageTypeClass" [documentId]="uploadedFile?.pages![0].id" [documentType]="documentType"
            [url]="uploadedFile?.pages![0].uri | cloudimageCrop:350:350" [title]="title" (deleteImage)="deleteImage($event, documentType)">
        </user-image>
    </div>
</div>
<p class="tip">{{uploadTextBottom}}</p>
<input #file type='file' accept='{{accept}}' (change)='fileChange($event)' class='invisible invisfile'>