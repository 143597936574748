import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { CalendarDetails, CalendarItem } from './calendar-item';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./style/calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @Input() calendar!: CalendarDetails;
    @Input() showMonth: number = 6;
    @Input() showMonthNavigation: boolean = true;
    @Output() dateSelected = new EventEmitter<CalendarItem>();

    ngOnInit(): void {
        if (this.calendar) {
            // console.info(`this.calendar:`, this.calendar);
            var first: Date = new Date(this.calendar.firstDate);
            var last: Date = new Date(this.calendar.lastDate);
            const showFrom = (this.showMonth > first.getMonth() ? this.showMonth : first.getMonth());
            //console.log(`Want to show from ${first.getMonth()} or ${this.showMonth}: ${showFrom}`);
            this.setDateDisplay(first, showFrom, last.getMonth(), first.toLocaleDateString("en-gb", { month: "long" }));
        }
    }

    showPreviousMonth(): void {
        // The current month should be decremented by one
        this.currentMonthIndex--;

        var first: Date = new Date(this.calendar.firstDate);

        var showMonth = this.currentMonthIndex;
        var displayFromMonth = new Date(first.getFullYear(), showMonth, 1);

        this.setDateDisplay(displayFromMonth, showMonth, showMonth + 1, displayFromMonth.toLocaleDateString("en-gb", { month: "long" }));
    }

    showNextMonth(): void {
        // The current month should be advanced by one
        this.currentMonthIndex++;

        var first: Date = new Date(this.calendar.firstDate);

        var showMonth = this.currentMonthIndex;
        var displayFromMonth = new Date(first.getFullYear(), showMonth, 1);

        this.setDateDisplay(displayFromMonth, showMonth, showMonth + 1, displayFromMonth.toLocaleDateString("en-gb", { month: "long" }));
    }

    setDateDisplay(firstDateInCalendar: Date, current: number, final: number, monthName: string): void {
        this.currentMonthIndex = current;
        this.lastMonthIndex = final;
        this.slots = [];

        var year = firstDateInCalendar.getFullYear();
        var lastDayOfMonth = this.getDaysInMonth(current, year);

        // Build slots from 'Sunday' to the first of the month
        var firstOfMonth = new Date(year, current, 1);
        var firstActualDayOfMonth = firstOfMonth.getDay();

        //console.log(`Current first: ${firstOfMonth}`);

        // IAS - use the actual first month, based on the input parameter
        this.currentMonthName = firstOfMonth.toLocaleDateString("en-gb", { month: "long" });

        //console.log(`Current first: ${firstOfMonth}`);

        // IAS - use the actual first month, based on the input parameter
        this.currentMonthName = firstOfMonth.toLocaleDateString("en-gb", { month: "long" });

        for (var i = 0; i < firstActualDayOfMonth; i++) {
            var slot = new Slot();
            slot.dayOfMonth = 0;
            this.slots.push(slot);
        }
        for (var i = 1; i <= lastDayOfMonth; i++) {
            var slot = new Slot();
            var dateUtc = Date.UTC(year, current, i);
            // console.log(`Date: ${year}/${current}/${i}: lastInMonth ${lastDayOfMonth}`);
            slot.dayOfMonth = i;

            const item = this.calendar.items.find(ci => new Date(ci.date).getTime() == dateUtc);
            if (item) {
                slot.day = new Day();
                slot.day.text = item.name;
                slot.day.css = item.class;
                slot.day.number = i;
                slot.day.calendarItem = Object.create(item);
                slot.day.calendarItem.name = item.name;
                slot.day.calendarItem.text = item.text;
                slot.day.calendarItem.date = this.getUtcDate(item.date);
                slot.day.selectable = item.selectable;
                slot.day.selectable = item.selectable;
            }
            this.slots.push(slot);
        }

        var first: Date = new Date(this.calendar.firstDate);
        var last: Date = new Date(this.calendar.lastDate);

        // CA-1808 don't forget fucking javascript date indices are zero-based
        let firstMonthIndex = first.getMonth();
        let lastMonthIndex = last.getMonth();

        // console.log(`current, firstMonth, lastMonth: ${this.currentMonthIndex}: ${firstMonthIndex}, ${lastMonthIndex}`);

        this.showEarlier = this.calendar.canViewEarlierDates || this.currentMonthIndex > firstMonthIndex;
        this.showLater = this.calendar.canViewLaterDates || this.currentMonthIndex < lastMonthIndex;

        // console.log(`showEarlier, showLater: ${this.showEarlier},${this.showLater}`);
    }

    getDaysInMonth(month: number, year: number): number {
        // Here January is 1 based
        //Day 0 is the last day in the previous month
        const d = new Date(year, month + 1, 0);
        return d.getDate();
    };


    selectDay(selected: Day): void {
        this.selectedDay = selected;
        this.dateSelected.emit(selected.calendarItem);
    }

    selectedDayMatches(current: Day): boolean {
        if (this.selectedDay) return (this.selectedDay && this.selectedDay.number === current.number);

        return false;
    }

    getUtcDate(date: Date): Date {
        let dateCopy = new Date(date);
        let dateUTC = new Date(dateCopy.getTime() + dateCopy.getTimezoneOffset() * 60000);
        return dateUTC;
    }

    currentMonthName: string = '';
    currentMonthIndex: number = 0;
    lastMonthIndex: number = 0;
    showEarlier: boolean = false;
    showLater: boolean = false;
    slots: Slot[] = [];
    selectedDay?: Day;
}

export class Slot {
    dayOfMonth!: number;
    dayIndex!: number;
    day?: Day;
    isValid(): boolean { return (this.day != undefined); }
}

export class Day {
    number!: number;
    text!: string;
    css!: string;
    isOccupied: boolean = false;
    calendarItem!: CalendarItem;
    selectable = false;
}
