<div class="calendar-control">
    <div class="calendar-month container">
        <div class="row navbar-header month-bar">
            <div class="col-2">
                <div class="month-nav month-prev" (click)="showPreviousMonth()" *ngIf="showMonthNavigation && showEarlier">
                    <span class="fa fa-arrow-circle-left"></span>
                </div>
            </div>
            <div class="col-8">
                <span class="name">{{currentMonthName}}</span>
            </div>
            <div class="col-2">
                <div class="month-nav month-next" (click)="showNextMonth()" *ngIf="showMonthNavigation && showLater">
                    <span class="fa fa-arrow-circle-right"></span>
                </div>
            </div>
        </div>
        <div class="month-days">
            <div class="days-heading">
                <span class="day weekend">S</span>
                <span class="day">M</span>
                <span class="day">T</span>
                <span class="day">W</span>
                <span class="day">T</span>
                <span class="day">F</span>
                <span class="day weekend">S</span>
            </div>
            <div class="days">
                <div *ngFor="let slot of slots; let i = index">
                    <div *ngIf="i > 0 && i % 7 == 0" class="week-break"></div>
                    <div *ngIf="slot.dayOfMonth == 0; else actual_day" class="no-day">
                        <span class="day-text">&nbsp;</span>
                        <span class="day-number">&nbsp;</span>
                    </div>
                    <ng-template #actual_day>
                        <div class="day" [class.selectable]="slot!.day!.selectable" [ngClass]="{'selected-day': selectedDayMatches(slot!.day!)}" (click)="selectDay(slot!.day!)" *ngIf="slot.isValid(); else empty_day">
                            <span class="day-text {{slot.day!.css}}">{{slot.day!.text}}</span>
                            <span class="day-number">{{slot.dayOfMonth}}</span>
                        </div>
                        <ng-template #empty_day>
                            <div class="empty-day">
                                <span class="day-text">&nbsp;</span>
                                <span class="day-number">{{slot.dayOfMonth}}</span>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>


</div>