import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home.component';
import { StatusComponent } from './status.component';
import { PublicTermsAndConditionsComponent } from './public-terms-and-conditions.component';
import { PageNotFoundComponent } from './not-found.component';

import { ExternalSignInComponent } from './external-sign-in.component';

import { ContactUsComponent } from './contact-us.component';

import { AuthGuard } from '@aifs-shared/auth/auth.guard';
import { RejectedApplicantGuard } from '@aifs-shared/auth/rejected-applicant.guard';
import { UrgentContactGuard } from '@aifs-shared/auth/urgent-contact.guard';
import { PrivacyPolicyComponent } from './privacy-policy.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'e/:token', component: ExternalSignInComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'status', component: StatusComponent, canActivate: [AuthGuard, UrgentContactGuard] },
    { path: 'terms-and-conditions', component: PublicTermsAndConditionsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'hub', loadChildren: () => import('./hub/hub.module').then(m => m.HubModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'placement', loadChildren: () => import('./placement/placement.module').then(m => m.PlacementModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'disclosure', loadChildren: () => import('./disclosure/disclosure.module').then(m => m.DisclosureModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'education', loadChildren: () => import('./education-work/education-work.module').then(m => m.EducationWorkModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'employment', loadChildren: () => import('./education-work/education-work.module').then(m => m.EducationWorkModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'form', loadChildren: () => import('./shared/metaform/metaform.module').then(m => m.MetaformModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'flights', loadChildren: () => import('./flight/flight.module').then(m => m.FlightModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'group-cover', loadChildren: () => import('./insurance/insurance.module').then(m => m.InsuranceModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'interview', loadChildren: () => import('./interview/interview.module').then(m => m.InterviewModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'interviewer', loadChildren: () => import('./interviewer/interviewer.module').then(m => m.InterviewerModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'messages', loadChildren: () => import('./messages/message.module').then(m => m.MessageModule), canActivate: [AuthGuard] },
    { path: 'payments', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'preferences', loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'register', loadChildren: () => import('./registration/register.module').then(m => m.RegisterModule) },
    { path: 'references', loadChildren: () => import('./reference/reference.module').then(m => m.ReferenceModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'sign-in', loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule) },
    { path: 'police-check', loadChildren: () => import('./police-check/police-check.module').then(m => m.PoliceCheckModule), canActivate: [AuthGuard] },
    { path: 'rollover', loadChildren: () => import('./rollover/rollover.module').then(m => m.RolloverModule) },
    { path: 'skills', loadChildren: () => import('./skills/skills.module').then(m => m.SkillsModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
    { path: 'uploads', loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'visa', loadChildren: () => import('./visa/visa.module').then(m => m.VisaModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'events', loadChildren: () => import('./event/event.module').then(m => m.EventModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'application-summary', loadChildren: () => import('./application-summary/application-summary.module').then(m => m.ApplicationSummaryModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'applicant-view', loadChildren: () => import('./applicant-view/applicant-view.module').then(m => m.ApplicantViewModule), canActivate: [AuthGuard] },
    { path: 'orientation', loadChildren: () => import('./orientation/orientation.module').then(m => m.OrientationModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: 'self-proposal', loadChildren: () => import('./self-proposal/self-proposal.module').then(m => m.SelfProposalModule), canActivate: [AuthGuard, RejectedApplicantGuard, UrgentContactGuard] },
    { path: '**', component: PageNotFoundComponent }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes,
            // ,
            { enableTracing: false } // <-- debugging purposes only
            // , { relativeLinkResolution: 'legacy' } -- removed in A15
        )],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
